import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../services/global.service";

@Component({
  selector: 'app-suivi',
  templateUrl: './suivi.component.html',
  styleUrls: ['./suivi.component.css']
})
export class SuiviComponent implements OnInit {
  clients: Array<any> = [];
  smpp: Array<any> = [];
  campagnes: Array<any> = [];
  idCampagne = "";
  isAdmin = false;
  isSousAdmin = false;
  userId: string | null = '';
  title = "";
  search = {
    debut: '',
    fin: ''
  }
  totalEnvoi = {
    total: 0,
    failed: 0,
    enAttente: 0,
    envoyer: 0
  };
  constructor(private route: ActivatedRoute,
              private httpClient: HttpClient,
              private globalService: GlobalService) { }

  ngOnInit(): void {
    if (localStorage.getItem("isAdmin") == "true") {
      this.isAdmin = true;
    }
    if (localStorage.getItem("isSousAdmin") == "true") {
      this.isSousAdmin = true;
    }
    this.userId = localStorage.getItem("id");
    this.getClients();
    this.getSmpp();
    setInterval(() => {
      this.reactualiser();
    }, 20000);
  }
  mettreArchive(id: string): void {
    this.httpClient
      .get<any>(this.globalService.url + '/campagnes/archive/' + id, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.getCampagnes();
        }
      }, () => {
      });
  }
  reactualiser(): void {
    this.totalEnvoi.envoyer = 0;
    this.totalEnvoi.failed = 0;
    this.totalEnvoi.enAttente = 0;
    this.totalEnvoi.total = 0;
    for (let c of this.campagnes) {
      this.getNombrePile('campagne', c._id);
      this.getNombreStop('campagne', c._id);
      this.getNombreTooLong('campagne', c._id);
      this.getNombreDuplicate('campagne', c._id);
      this.getNombreReceptions('campagne', c._id);
    }
    for (let c of this.smpp) {
      this.getNombrePile('user', c._id);
      this.getNombreStop('user', c._id);
      this.getNombreTooLong('user', c._id);
      this.getNombreDuplicate('user', c._id);
      this.getNombreReceptions('user', c._id);
    }
  }
  relancer(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/api/resend_failed', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          console.log(response);
        }
      }, () => {
      });
  }
  getClients(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/users/users', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.clients = response.personnels;
          for (let c of this.clients) {
            c.assezCredit = true;
          }
          this.getCampagnes();
        }
      }, () => {
      });
  }
  async calculQuota(type: string): Promise<void> {
    await new Promise(resolve => setTimeout(resolve, 2000));
    if (type == 'client') {
      for (let c of this.clients) {
        let quota = c.credit;
        let quotaNecessaire = 0;

        for (let camp of this.campagnes) {
          if (camp.idClient == c._id) {
            quotaNecessaire += camp.enAttente;
          }
        }

        if (quotaNecessaire > quota) {
          c.assezCredit = false;
        } else {
          c.assezCredit = true;
        }
      }
    } else {
      for (let s of this.smpp) {
        let quota = s.credit;
        let quotaNecessaire = s.enAttente;

        console.log(quotaNecessaire, quota);

        if (quotaNecessaire > quota) {
          s.assezCredit = false;
        } else {
          s.assezCredit = true;
        }
      }
    }
  }
  getSmpp(): void {
    this.httpClient
      .get<any>(this.globalService.url + '/users/smpp', {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (this.isAdmin || this.isSousAdmin) {
            this.smpp = response.smpp;
            for (let s of this.smpp) {
              s.assezCredit = true;
            }
            this.calculQuota('smpp');
          } else {
            for (let smpp of response.smpp) {
              if (smpp._id == this.userId) {
                this.smpp.push(smpp);
              }
            }
          }

          for (let c of this.smpp) {
            this.getNombrePile('user', c._id);
            this.getNombreStop('user', c._id);
            this.getNombreReceptions('user', c._id);
            this.getNombreTooLong('user', c._id);
            this.getNombreDuplicate('user', c._id);
          }
        }
      }, () => {
      });
  }
  getCampagnes(): void {
    this.campagnes = [];
    this.httpClient
      .get<any>(this.globalService.url + '/campagnes',
        {headers: this.globalService.headers()})
      .subscribe(async (response) => {
        if (response.status) {
          this.campagnes = response.campagnes;
          for (let c of this.campagnes) {
            await this.getNombrePile('campagne', c._id);
            await this.getNombreStop('campagne', c._id);
            await this.getNombreReceptions('campagne', c._id);
            await this.getNombreTooLong('campagne', c._id);
            await this.getNombreDuplicate('campagne', c._id);
          }
          this.calculQuota('client');
        }
      }, () => {
      });
  }
  updatePriorite(idCampagne: string): void {
    for (let c of this.campagnes) {
      if (c._id == idCampagne) {
        this.httpClient
          .get<any>(this.globalService.url + '/campagnes/update-priorite/'+idCampagne+'/'+c.priorite,
            {headers: this.globalService.headers()})
          .subscribe((response) => {
            if (response.status) {
              alert('Priorité modifiée');
            }
          }, () => {
          });
        break;
      }
    }
  }
  getNombrePile(type: string, id: string): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/nombre/' + type + '/' + id + '/' + debut + '/' + fin,
        {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (type == 'campagne') {
            for (let c of this.campagnes) {
              if (c._id == id) {
                c.total = response.total;
                c.failed = response.failed;
                c.enAttente = response.enAttente;
                c.envoyer = response.envoyer;

                this.totalEnvoi.total += c.total;
                this.totalEnvoi.failed += c.failed;
                this.totalEnvoi.envoyer += c.envoyer;

                let actuel = Math.round(new Date().getTime() / 1000);
                if (actuel > c.schedule) {
                  this.totalEnvoi.enAttente += c.enAttente;
                }
                break;
              }
            }
          } else {
            for (let s of this.smpp) {
              if (s._id == id) {
                s.total = response.total;
                s.failed = response.failed;
                s.enAttente = response.enAttente;
                s.envoyer = response.envoyer;

                this.totalEnvoi.total += s.total;
                this.totalEnvoi.failed += s.failed;
                this.totalEnvoi.enAttente += s.enAttente;
                this.totalEnvoi.envoyer += s.envoyer;

                break;
              }
            }
          }
        }
      }, () => {
      });
  }
  getNombreStop(type: string, id: string): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/stop/' + type + '/' + id + '/' + debut + '/' + fin,
        {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (type == 'campagne') {
            for (let c of this.campagnes) {
              if (c._id == id) {
                c.nombreStop = response.total;
                break;
              }
            }
          } else {
            for (let s of this.smpp) {
              if (s._id == id) {
                s.nombreStop = response.total;
                break;
              }
            }
          }
        }
      }, () => {
      });
  }
  getNombreTooLong(type: string, id: string): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/tooLong/' + type + '/' + id + '/' + debut + '/' + fin,
        {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (type == 'campagne') {
            for (let c of this.campagnes) {
              if (c._id == id) {
                c.nombreTooLong = response.total;
                break;
              }
            }
          } else {
            for (let s of this.smpp) {
              if (s._id == id) {
                s.nombreTooLong = response.total;
                break;
              }
            }
          }
        }
      }, () => {
      });
  }
  getNombreDuplicate(type: string, id: string): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/duplicate/' + type + '/' + id + '/' + debut + '/' + fin,
        {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (type == 'campagne') {
            for (let c of this.campagnes) {
              if (c._id == id) {
                c.nombreDuplicate = response.total;
                break;
              }
            }
          } else {
            for (let s of this.smpp) {
              if (s._id == id) {
                s.nombreDuplicate = response.total;
                break;
              }
            }
          }
        }
      }, () => {
      });
  }
  getNombreReceptions(type: string, id: string): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/receptions/nombre/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (type == 'campagne') {
            for (let c of this.campagnes) {
              if (c._id == id) {
                c.nombreReception = response.nombre;
                break;
              }
            }
          } else {
            for (let s of this.smpp) {
              if (s._id == id) {
                s.nombreReception = response.nombre;
                break;
              }
            }
          }
        }
      }, () => {
      });
  }
  updateIsOn(url: string, id: string, isOn: string): void {
    this.httpClient
      .post<any>(this.globalService.url + '/'+url+'/isOn/', {id: id, isOn: isOn}, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          if (url == 'campagnes') {
            for (let c of this.campagnes) {
              if (c._id == id) {
                c.isOn = isOn;
                break;
              }
            }
          } else {
            for (let s of this.smpp) {
              if (s._id == id) {
                s.isOn = isOn;
                break;
              }
            }
          }
        }
      }, () => {
      });
  }
  exporterFailed(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportFailed/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/failed/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterStop(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportStop/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/stops/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterDelivered(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportDelivered/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/delivered/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterTooLong(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportTooLong/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/tooLong/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterDuplicate(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportDuplicate/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/duplicate/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterPile(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportPile/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/pile/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  exporterTotal(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/sms/exportTotal/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/total/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  supprimerSMPP(idUser: string, number: number) {
    this.httpClient
      .delete<any>(this.globalService.url + '/sms/' + idUser + '/' +number, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.reactualiser();
        }
      }, () => {
      });
  }
  exporterReceptions(type: string, id: number): void {
    let debut = this.search.debut;
    let fin = this.search.fin;
    if (debut == '') {
      debut = '-1';
    }

    if (fin == '') {
      fin = '-1';
    }
    this.httpClient
      .get<any>(this.globalService.url + '/receptions/export/' + type + '/' + id + '/' + debut + '/' + fin, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          window.open(this.globalService.url + '/documents/reception/' + response.filename + '.xlsx');
        } else {
          alert('Erreur lors de la modification');
        }
      }, () => {
      });
  }
  supprimer(id: string): void {
    if (confirm('Eves-vous sur de vouloir supprimer cet envoi ?')) {
      this.httpClient
        .delete<any>(this.globalService.url + '/campagnes/' + id, {headers: this.globalService.headers()})
        .subscribe((response) => {
          if (response.status) {
            this.getCampagnes();
          } else {
            alert('Erreur lors de la suppression');
          }
        }, () => {
        });
    }
  }
  relancerFailed(id: string): void {
    this.httpClient
      .get<any>(this.globalService.url + '/campagnes/relancer-failed/' + id, {headers: this.globalService.headers()})
      .subscribe((response) => {
        if (response.status) {
          this.getCampagnes();
        } else {
          alert('Erreur lors de la suppression');
        }
      }, () => {
      });
  }
}
